import React from "react"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../GatsbyLink"
import * as styles from "../../stylesheets/slices/imgText2Col.module.scss"

const ImgText2Col = ({ slice, darkMode }) => {
  console.log(slice)

  return (
    <div className={`${styles.container} ${darkMode && styles.dark}`}>
      <RichText
        render={slice.primary.title1.richText}
        serializeHyperlink={GatsbyLink}
      />
      <div className={styles.contentContainer}>
        <img
          src={slice.primary.image.url}
          alt={slice.primary.image.alt}
          className={styles.image}
        />
        <RichText
          render={slice.primary.text.richText}
          serializeHyperlink={GatsbyLink}
        />
      </div>
    </div>
  )
}

export default ImgText2Col
