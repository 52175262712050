import React from "react"
import * as styles from "../../stylesheets/slices/bannerImage.module.scss"

const BannerImage = ({ slice }) => {
  const titleColor = slice.primary.title_color ? "dark" : "light"
  const darkenAmount = slice.primary.darken_amount / 10

  return (
    <div className={styles.container}>
      <div
        className={styles.imageContainer}
        style={
          slice.primary.darken_image
            ? {
                backgroundImage: `url(${slice.primary.image.url})`,
                backgroundColor: `rgba(0,0,0,${darkenAmount})`,
                backgroundBlendMode: "darken",
              }
            : { backgroundImage: `url(${slice.primary.image.url})` }
        }
      >
        {slice.primary.main_title ? (
          <h1
            className={`${styles.title} ${
              slice.primary.title_color && styles.dark
            }`}
          >
            {slice.primary.title1.text}
          </h1>
        ) : (
          <h2 className={styles.title}>{slice.primary.title1.text}</h2>
        )}
      </div>
    </div>
  )
}

export default BannerImage
