import React from "react"
import { Link } from "gatsby"
import * as styles from "../../stylesheets/slices/button.module.scss"

const Button = ({ slice }) => {
  return (
    <Link to={slice.primary.slug.text} className={styles.container}>
      <button className={styles.button}>
        {slice.primary.button_text.text}
      </button>
    </Link>
  )
}

export default Button
