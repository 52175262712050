import React from "react"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../GatsbyLink"

const Quote = ({ slice }) => (
  <div className="post-quote container">
    <blockquote>
      <RichText
        render={slice.primary.quote.richText}
        serializeHyperlink={GatsbyLink}
      />
    </blockquote>
  </div>
)

export default Quote
