import React from "react"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../GatsbyLink"
import * as styles from "../../stylesheets/slices/textWithBgImage.module.scss"

const TextWithBgImage = ({ slice, darkMode }) => {
  console.log(slice.primary)
  return (
    <div className={`${styles.container} ${darkMode && styles.dark}`}>
      <RichText
        render={slice.primary.text.richText}
        serializeHyperlink={GatsbyLink}
      />
      <img
        src={slice.primary.image.url}
        alt={slice.primary.image.alt}
        style={{
          height: `${slice.primary.height}px`,
          opacity: `${slice.primary.opacity / 100}`,
          top: `${slice.primary.y_position}%`,
          left: `${slice.primary.x_position}%`,
        }}
      />
    </div>
  )
}

export default TextWithBgImage
