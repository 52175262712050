import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layouts/Layout.js"
import {
  BannerImage,
  ImageCaption,
  Quote,
  Text,
  Divider,
  DividerLine,
  TextWithBgImage,
  Button,
  ImgText2Col,
} from "../components/slices"

// Query for the Page content in Prismic
export const query = graphql`
  query PageQuery($uid: String) {
    prismicPage(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        page_style
        body {
          ... on PrismicPageDataBodyBannerWithTitle {
            id
            slice_type
            slice_label
            primary {
              darken_amount
              darken_image
              image {
                alt
                url
              }
              main_title
              title1 {
                text
                richText
              }
              title_color
              use_parallax
            }
          }
          ... on PrismicPageDataBodyRichText {
            id
            slice_label
            slice_type
            primary {
              text {
                text
                richText
              }
            }
          }
          ... on PrismicPageDataBodyDivider {
            id
            slice_label
            slice_type
          }
          ... on PrismicPageDataBodyButton {
            id
            slice_label
            slice_type
            primary {
              button_text {
                richText
                text
              }
              slug {
                text
              }
            }
          }
          ... on PrismicPageDataBodyTextWithBgImage {
            id
            slice_label
            slice_type
            primary {
              y_position
              x_position
              text {
                text
                richText
              }
              opacity
              height
              image {
                alt
                url
              }
            }
          }
          ... on PrismicPageDataBodyDivider1 {
            id
            slice_label
            slice_type
          }
          ... on PrismicPageDataBodyImgAndText {
            id
            slice_type
            slice_label
            primary {
              text {
                richText
                text
              }
              image {
                alt
                url
              }
              title1 {
                text
              }
            }
          }
        }
      }
    }
  }
`

// Sort and display the different slice options
const PostSlices = ({ slices, darkMode }) => {
  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.slice_type) {
        case "rich_text":
          return (
            <div key={index}>{<Text slice={slice} darkMode={darkMode} />}</div>
          )

        case "quote":
          return <div key={index}>{<Quote slice={slice} />}</div>

        case "image_with_caption":
          return <div key={index}>{<ImageCaption slice={slice} />}</div>

        case "banner_with_title":
          return (
            <div key={index}>
              <BannerImage slice={slice} darkMode={darkMode} />
            </div>
          )

        case "divider":
          return (
            <div key={index} darkMode={darkMode}>
              <Divider />
            </div>
          )

        case "divider1":
          return (
            <div key={index} darkMode={darkMode}>
              <DividerLine />
            </div>
          )

        case "text_with_bg_image":
          return (
            <div key={index}>
              <TextWithBgImage slice={slice} darkMode={darkMode} />
            </div>
          )

        case "img_and_text":
          return (
            <div key={index}>
              <ImgText2Col slice={slice} darkMode={darkMode} />
            </div>
          )

        case "button":
          return (
            <div key={index}>
              <Button slice={slice} darkMode={darkMode} />
            </div>
          )

        default:
          return
      }
    })()
    return res
  })
}

const Page = (props) => {
  const doc = props.data.prismicPage.data
  if (!doc) return null
  console.log(props)
  return (
    <Layout darkMode={doc.page_style}>
      <PostSlices slices={doc.body} darkMode={doc.page_style} />
    </Layout>
  )
}

export default Page
