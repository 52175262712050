import React from "react"
import { RichText } from "prismic-reactjs"
import GatsbyLink from "../GatsbyLink"
import * as styles from "../../stylesheets/slices/text.module.scss"

const Text = ({ slice, darkMode }) => {
  return (
    <div className={`${styles.container} ${darkMode && styles.dark}`}>
      <RichText
        render={slice.primary.text.richText}
        serializeHyperlink={GatsbyLink}
      />
    </div>
  )
}

export default Text
